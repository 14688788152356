import React from "react" 
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from '@bit/azheng.joshua-tree.button';
import CTA from "../components/CTA/CTA"
import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"
import { Link, graphql } from "gatsby"
import { instructions } from "../cms/collections/instructions"
import Stars from "../components/SocialReviews/Stars"
import SEO from "../components/seo"
import ABOMS from "../images/logo-ABOMS.svg"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

const DoctorOnePage = (props) => {

  const post = props.data.allUniquePagesJson.nodes[0];
  const doctor = props.data.drBarefootJson.data;


  return (
    <SharedStateProvider>
      <Layout>
      <SEO title={post.metaTitle} description={post.metaDescription} />
        <div className="columns top-section doctor is-vcentered">
          <Button buttonText="BACK" goBack href="/about-our-practice/" />
          <div className="column"></div>
          <div className="column doctor-image-wrapper is-11">
          <NuvoImage
            useAR
              cloudName="nuvolum"
              publicId={post.cutoutImage}
              width="auto"
              responsive
            >
              
            </NuvoImage>
            {/* {post.badge && post.badge.hasBadge && 
            <img className="badge" src={ABOMS} />
            } */}

          </div>

          <div className="column doctor-top-intro is-8">
          <h1>
            {post.heading}
            </h1>
            <p className="large-doctor-text large">
            {post.topBlurb}
            </p>
            {/* <div className="rating-review">
              <div className="featured-social has-text-centered">
                <div className="review-icons">
                <h4 className="google-rating">{doctor.rating.toFixed(1)}</h4>
                  <Stars rating={doctor.rating} />
                </div>

                <a style={{fontSize:"1.125rem"}} href="https://www.google.com/maps/place/Barefoot+Oral+and+Facial+Surgery/@32.8308905,-79.8237412,17z/data=!4m12!1m6!3m5!1s0x88fe721b3103ea51:0x92efbf8769e58ed!2sBarefoot+Oral+and+Facial+Surgery!8m2!3d32.8308905!4d-79.8215525!3m4!1s0x88fe721b3103ea51:0x92efbf8769e58ed!8m2!3d32.8308905!4d-79.8215525" target="_blank">
                {doctor.totalReviews} Reviews
                </a>
              </div>
            </div> */}

            <div className="rating-review columns is-vcentered">
            {!post.badge.hasBadge  
              ? <div className="column">
                  <div className="featured-social has-text-centered">
                    <div className="review-icons">
                    <h4 className="google-rating">{doctor.rating.toFixed(1)}</h4>
                      <Stars rating={doctor.rating} />
                    </div>

                    <a style={{fontSize:"1.125rem"}} href="https://www.google.com/maps/place/Barefoot+Oral+and+Facial+Surgery/@32.8308905,-79.8237412,17z/data=!4m12!1m6!3m5!1s0x88fe721b3103ea51:0x92efbf8769e58ed!2sBarefoot+Oral+and+Facial+Surgery!8m2!3d32.8308905!4d-79.8215525!3m4!1s0x88fe721b3103ea51:0x92efbf8769e58ed!8m2!3d32.8308905!4d-79.8215525" target="_blank">
                    {doctor.totalReviews} Reviews
                    </a>
                  </div>
                </div>

              : <>
                <div className="column is-12">
                  <div className="featured-social has-text-centered">
                  <div className="review-icons">
                  <h4 className="google-rating">{doctor.rating.toFixed(1)}</h4>
                    <Stars rating={doctor.rating} />
                  </div>

                  <a style={{fontSize:"1.125rem"}} href="https://www.google.com/maps/place/Barefoot+Oral+and+Facial+Surgery/@32.8308905,-79.8237412,17z/data=!4m12!1m6!3m5!1s0x88fe721b3103ea51:0x92efbf8769e58ed!2sBarefoot+Oral+and+Facial+Surgery!8m2!3d32.8308905!4d-79.8215525!3m4!1s0x88fe721b3103ea51:0x92efbf8769e58ed!8m2!3d32.8308905!4d-79.8215525" target="_blank">
                  {doctor.totalReviews} Reviews
                  </a>
                </div>
                </div>

                <div className="column is-3"></div>
                <div className="column is-6 has-text-centered-mobile">
                  {post.badge && post.badge.hasBadge && 
                    <img className="badge" src={ABOMS} />
                  }
                </div>
              </>
            }
          </div>
          </div>
          <div className="column"></div>
        </div>

        <div
          className="body-section about-doc-block"
          style={{ paddingBottom: "100px", paddingTop: "32px" }}
        >
          <div className="columns">
            <div className="column is-5"></div>
            <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.aboutSection.body)
                  )}>
            </div>
            <div className="column is-5"></div>
          </div>
          <div className="columns">
            <div className="column is-5"></div>
            <div className="column has-text-centered">
            <TheaterVideo
        videoUrl={`https://www.youtube.com/watch?v=${post.aboutSection.youtube}`}
                controls={true}
                playing={true}
                overVideo={false}
                onlyButton={true}
              ></TheaterVideo>
            </div>
            <div className="column is-5"></div>
          </div>
        </div>

        <div className="body-section doc-bio" style={{ paddingTop: "0"}}>
          <div className="columns is-vcentered">
            <div className="column is-1"></div>
            <div className="column is-12">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.educationResidency.imageId}
                responsive
                width="auto"
              >
                
              </ImageMeta>
            </div>
            <div className="column is-1"></div>
            <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.educationResidency.body)
                  )}>

            </div>
            <div className="column is-2"></div>
          </div>
        </div>

        <div className="body-section doc-bio certifications"  style={{ paddingTop: "0"}}>
          <div className="columns is-vcentered reverse-columns">
            <div className="column is-1"></div>

            <div className="column" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.certifications.body)
                  )}></div>

            <div className="column is-1"></div>

            <div className="column is-12">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.certifications.imageId}
                responsive
                width="auto"
              ></ImageMeta>
            </div>

            <div className="column is-1"></div>
          </div>
        </div>


        <div className="body-section doctor doc-bio" style={{ paddingBottom: "0", paddingTop: "0" }}>
          <div className="columns is-vcentered">
            <div className="column is-2"></div>
            <div className="column is-10">
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.hobbiesInterests.imageId}
                responsive
                width="auto"
              >
                
              </ImageMeta>
            </div>
            <div className="column is-1"></div>
            <div className="column hobbies-title" dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.hobbiesInterests.body)
                  )}>
          
            </div>
            <div className="column is-3"></div>
          </div>
        </div>

        <div className="body-section" style={{ paddingBottom: "80px", paddingTop: "97px" }}>
          <div className="columns has-text-centered">
            <div className="column is-5"></div>
            <div className="column">
            <h3 style={{ marginBottom: "1.125rem" }}>{post.learnMore.heading}</h3>
            </div>
            <div className="column is-5"></div>
          </div>
          <div className="columns has-text-centered">
            <div className="column is-4"></div>
            <div className="column mobile-col">
              <p>
              {post.learnMore.blurb}
              </p>
            </div>
            <div className="column is-4"></div>
          </div>
          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
              <div className="button-group-wrapper">
                <div className="button-group is-centered two">
                  <Button
                    buttonText={post.learnMore.buttonOne.buttonText}
                    href={post.learnMore.buttonOne.href}
                  />
                  {/* <Button
                    buttonText="About Us"
                    href="/about-our-practice/"
                  /> */}
                  <Button 
                    buttonText={post.learnMore.buttonTwo.buttonText}
                    href={post.learnMore.buttonTwo.href}
                  />
                </div>
              </div>
            </div>
            <div className="column is-4"></div>
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
query bryanPage ($loneUrl: String!)  {
  drBarefootJson {
    data {
      rating
      totalReviews
      url
    }
  }
  allUniquePagesJson(filter: {title: {eq: $loneUrl}}) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        badge {
          hasBadge
          badgeImage
        }
        topBlurb
        cutoutImage
        aboutSection {
          body
          youtube
        }
        educationResidency {
          body
          imageId
        }
        hobbiesInterests {
          body
          imageId
        }
        certifications {
          body
          imageId
        }
        learnMore {
          heading
          blurb
          buttonOne {
            buttonText
            href
          }
          buttonTwo {
            buttonText
            href
          }
        }
      }
    }
  }
`

export default DoctorOnePage
